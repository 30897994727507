import * as React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { SectionContainer } from "../layout";

export const Security = ({ title, description, certifications }) => {
  return (
    <SectionContainer className="bg-action-300 bg-opacity-25">
      <div className="max-w-7xl mx-auto">
        <div className="lg:grid lg:grid-cols-5 lg:gap-12">
          <h2 className="max-w-md mx-auto text-center lg:max-w-xl lg:text-left lg:col-span-2">
            <span className="block text-xl font-bold text-action-600">
              {title}
            </span>
            <span className="mt-2 block text-base text-gray-500">
              {description}
            </span>
          </h2>
          <div className="flow-root self-center mt-8 lg:mt-0 lg:col-span-3">
            <div className="flex flex-wrap justify-around lg:-ml-4 -mx-4">
              {certifications.map(c => (
                <div
                  className="flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 w-1/2 sm:w-auto mt-4 sm:mt-0"
                  key={c.asset._id}
                >
                  <SanityImage width={150} className="max-h-14 w-auto" {...c} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};
