import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import hospital from "../../images/hospital.svg";
import cptsEss from "../../images/cptsEss.svg";
import clinic from "../../images/clinic.svg";
import gp from "../../images/gp.svg";
import specialist from "../../images/specialist.svg";
import otherProfessional from "../../images/otherProfessional.svg";
import { Card } from "../atoms/Card";
import { SectionContainer } from "../layout";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Roles = ({
  title,
  description,
  specialistsDescription,
  gpDescription,
  otherIndividualsDescription,
  hospitalsDescription,
  cptsEssDescription,
  otherGroupsDescription,
}) => {
  const tabs = [
    {
      name: "Organisations",
      items: [
        {
          title: "Hôpitaux publics et ESPIC",
          href: "/hopitaux",
          description: hospitalsDescription,
          icon: hospital,
        },
        {
          title: "CPTS et ESS",
          href: "/cpts-ess",
          description: cptsEssDescription,
          icon: cptsEss,
        },
        {
          title: "Cliniques et cabinets de groupe",
          href: "/cliniques-et-cabinets-de-groupe",
          description: otherGroupsDescription,
          icon: clinic,
        },
      ],
    },
    {
      name: "Professionnels de santé",
      items: [
        {
          title: "Médecins spécialistes",
          href: "/medecins-specialistes",
          description: specialistsDescription,
          icon: specialist,
        },
        {
          title: "Médecins généralistes",
          href: "/medecins-generalistes",
          description: gpDescription,
          icon: gp,
        },
        {
          title: "Autres professionnels de santé",
          href: "/autres-professionnels-de-sante",
          description: otherIndividualsDescription,
          icon: otherProfessional,
        },
      ],
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].name);
  function onTabChange(tabName) {
    setActiveTab(tabName);
  }

  return (
    <SectionContainer
      title={title}
      description={description}
      className="bg-gray-50"
    >
      <div className="sm:hidden pt-6 px-4 sm:px-4 lg:pt-12 lg:px-6">
        <label htmlFor="tabs" className="sr-only">
          Sélectionnez un onglet
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-action-500 focus:border-action-500 border-gray-300 rounded-md"
          onChange={e => onTabChange(e.target.value)}
          value={activeTab}
        >
          {tabs.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block px-4 sm:px-4 lg:px-6 my-16">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 max-w-xl mx-auto lg:max-w-4xl"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              name={tab.name}
              className={classNames(
                tab.name === activeTab
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "text-xl group relative min-w-0 w-50 flex-1 overflow-hidden bg-white py-4 px-4 font-medium text-center hover:bg-white focus:z-10",
              )}
              aria-current={tab === activeTab ? "page" : undefined}
              onClick={e => onTabChange(e.target.name)}
            >
              <span style={{ pointerEvents: "none" }}>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.name === activeTab ? "bg-action-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5",
                )}
                style={{ pointerEvents: "none" }}
              />
            </button>
          ))}
        </nav>
      </div>

      <div className="relative max-w-7xl mx-auto">
        <div className="mt-8 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none lg:mt-12">
          {tabs
            .find(tab => tab.name === activeTab)
            .items.map(item => (
              <Card
                title={item.title}
                href={item.href}
                description={item.description}
                icon={item.icon}
              />
            ))}
        </div>
      </div>
    </SectionContainer>
  );
};
