import * as React from "react";
import { LogoCloud } from "../atoms/LogoCloud";
import { SectionContainer } from "../layout";

export const References = ({ header, references, useLargeLogos = false }) => {
  return (
    <SectionContainer title={header}>
      <LogoCloud items={references.logos} useLargeLogos={useLargeLogos} />
    </SectionContainer>
  );
};
