import * as React from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { SectionContainer } from "../layout";
import { FeatureContainer } from "../layout/FeatureContainer";

export const Features = ({ title, description, features }) => {
  return (
    <SectionContainer
      title={title}
      description={description}
      className="bg-gray-50 relative overflow-hidden"
    >
      <div className="mx-auto">
        {features &&
          features.map((feature, idx) => (
            <FeatureContainer
              key={feature.title}
              variant={idx % 2 === 0 ? "left" : "right"}
              title={feature.title}
              description={feature._rawDescription}
            >
              {feature.image && feature.image.asset && (
                <div className="relative overflow-hidden" aria-hidden="true">
                  <svg
                    className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                    ></rect>
                  </svg>
                  <div className="max-w-xl lg:max-w-none mx-auto">
                    <SanityImage
                      {...feature.image}
                      className="relative mx-auto drop-shadow-md w-full"
                      width={600}
                    />
                  </div>
                </div>
              )}
            </FeatureContainer>
          ))}
      </div>
    </SectionContainer>
  );
};
